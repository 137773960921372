import qs from 'qs'

import upushRequest from './uPush'
import { upushKey } from './constants'

const addUPushClickEvent = (el, tag) => {
  el.addEventListener('click', () => {
    window.hj && window.hj('vpv', '/cta-click')
    window.gtag && window.gtag('event', 'Click', {
      event_category: 'Ad',
      event_label: el.innerHTML.trim()
    })
    requestUpush(tag)
  })
}

export const requestUpush = (tag, delayNotification = false) => {
  const params = qs.parse(window.location.search.slice(1))
  params['push-tag'] && tag.push(params['push-tag'])
  const updatedTags = updateTags(tag)
  saveTagsToStorage(updatedTags)
  if (!params['no-push']) {
    if (delayNotification) {
      return setTimeout(() => {
        upushRequest(upushKey, { tags: updatedTags })
      }, 10 * 1000)
    }
    upushRequest(upushKey, { tags: updatedTags })
  }
}

export const updateTags = (newTags) => {
  let tags = []
  tags = newTags.reduce((acc, currentTag) => {
    if (!tags.includes(currentTag)) return acc.concat(currentTag)
    return acc
  }, tags)
  return tags
}

export const saveTagsToStorage = (tagsToStore) => {
  window.localStorage.setItem('tags', JSON.stringify(tagsToStore))
}

export default addUPushClickEvent
