import { once } from 'lodash'
import browserCookie from 'browser-cookies'
import {
  NOTIFICATION_KEY,
  MILLISECONDS_1_WEEK,
  MILLISECONDS_1_MONTH
} from './constants'

function postDevice (subscription, apiKey, options) {
  (function () {
    subscription = JSON.parse(JSON.stringify(subscription))
    const objJson = JSON.stringify(options)
    console.log(objJson)
    const xhr = new XMLHttpRequest()
    xhr.open('POST', 'https://api.upush.co/index.php?do=newDevice')
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded')
    xhr.send('upush_data=' + objJson + '&endpoint=' + subscription.endpoint + '&pkey=' + subscription.keys.p256dh + '&akey=' + subscription.keys.auth + '&api_key=' + apiKey)
  })()
}

function initialiseState (apiKey, options) {
  if (!('showNotification' in ServiceWorkerRegistration.prototype)) {
    console.warn('Notifications aren\'t supported.')
    return
  }
  if (Notification.permission === 'denied') {
    console.warn('The user has blocked notifications.')
    return
  }
  if (!('PushManager' in window)) {
    console.log('Push messaging isn\'t supported.')
    return
  }
  navigator.serviceWorker.ready.then(function (serviceWorkerRegistration) {
    serviceWorkerRegistration.pushManager
      .getSubscription()
      .then(function (subscription) {})
      .catch(function (err) {
        console.log('uPush: Error during getSubscription()', err)
      })
  }).catch(function (err) {
    console.log('uPush: Error during initialiseState()', err)
  })
}

function urlBase64ToUint8Array (base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4)
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/')
  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

const checkRemotePermission = function (permissionData, apiKey, options) {
  if (permissionData.permission === 'default') {
    window.safari.pushNotification.requestPermission('https://sdk.upush.co', 'web.co.upush.123', {}, checkRemotePermission)
  } else if (permissionData.permission === 'granted') {
    const subscription = {}
    subscription.endpoint = permissionData.deviceToken
    subscription.keys = {}
    subscription.keys.p256dh = ''
    subscription.keys.auth = ''
    postDevice(subscription, apiKey, options)
  }
}

function upushPermission (apiKey, options) {
  if ('safari' in window && 'pushNotification' in window.safari) {
    const permissionData = window.safari.pushNotification.permission('web.co.upush')
    checkRemotePermission(permissionData, apiKey, options)
  } else {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/uPushSW.js').then(initialiseState(apiKey, options))
    } else {
      console.log('uPush: Service workers aren\'t supported in this browser.')
    }
    navigator.serviceWorker.ready.then(function (serviceWorkerRegistration) {
      serviceWorkerRegistration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array('BI-UEhKUwfCQO2lUKKdQuLXEcc2hIywzVM8eOnzWICjz8LyCM5KNpPO4_Lz9CzI3nz2RYqhnNay3HMbwQZk1CvY')
      }).then(function (subscription) {
        return postDevice(subscription, apiKey, options)
      }, function (err) {
        console.log('uPush: Cannot subscribe()')
        console.log(err)
        // permission denied or notification is blocked, so hide popup for a month
        setNotificationCookie(MILLISECONDS_1_MONTH)
      }).catch(function (e) {
        if (Notification.permission === 'denied') {
          console.log('uPush: Permission for Notifications was denied')
          // permission denied or notification is blocked, so hide popup for a month
          setNotificationCookie(MILLISECONDS_1_MONTH)
        } else {
          console.log('uPush: Unable to subscribe to push.', e)
        }
      })
    }).catch(function (e) {
      console.log('uPush: Subscription error: ', e)
    })
  }
}

function setNotificationCookie (expiry) {
  browserCookie.set(NOTIFICATION_KEY, 'false', {
    expires: new Date(Date.now() + expiry),
    samesite: 'Strict'
  })
}

function upushRequest (apiKey, options) {
  // check if uPush is required or not
  if (typeof options !== 'undefined' && typeof options.template !== 'undefined') {
    return
  }
  if (typeof window.device !== 'undefined' && !window.device.desktop()) {
    return upushPermission(apiKey, options)
  }
  getSubscription(function (err, subscription) {
    if (!err && subscription) {
      return upushPermission(apiKey, options)
    }

    // if soft ask popup has been shown once in session or rejected in last 1 week, skip it.
    if (
      typeof window.softNotification === 'undefined' &&
      !browserCookie.get(NOTIFICATION_KEY)
    ) {
      // subscription not found, show soft ask
      const popupContainer = document.getElementById('permission--container')
      popupContainer.style.display = 'block'

      document.addEventListener(
        'soft.notification',
        function (event) {
          window.softNotification = event.detail.isAllowed
          if (event.detail.isAllowed) {
            return upushPermission(apiKey, options)
          }
          // notification rejected, store it so we don't display popup for 1 week
          setNotificationCookie(MILLISECONDS_1_WEEK)
        },
        { once: true }
      )
    }
  })
}

/**
 * returns err,subscription in callback
 * @param {*} subscriptionCb
 */
export function getSubscription (subscriptionCb) {
  const cb = once(subscriptionCb)

  if ('safari' in window && 'pushNotification' in window.safari) {
    // unsubscribe is not supported in safari
    return cb(null)
  }
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/uPushSW.js').then(function () {
      if (!('showNotification' in ServiceWorkerRegistration.prototype)) {
        return cb(null, null)
      }
      if (Notification.permission === 'denied') {
        return cb(null, null)
      }
      if (!('PushManager' in window)) {
        return cb(null, null)
      }
    })
    navigator.serviceWorker.ready.then(function (serviceWorkerRegistration) {
      serviceWorkerRegistration.pushManager
        .getSubscription()
        .then(function (subscription) {
          cb(null, subscription)
        })
        .catch(function (err) {
          cb(err, null)
        })
    })
  } else {
    return cb(null, null)
  }
}

/**
 * takes subscription and calls unsubscribe
 * returns err,success in callback
 * @param {*} subscription
 * @param {*} cb
 */
export function unSubscribe (subscription, cb) {
  if (subscription && typeof subscription.unsubscribe === 'function') {
    return subscription
      .unsubscribe()
      .then(function (successful) {
        cb(null, successful)
      })
      .catch(function (err) {
        cb(err, false)
      })
  }
  return cb(new Error('subscription not found'), true)
}

export default upushRequest
