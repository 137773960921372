export const countries = {
  USA: 'US',
  Australia: 'AU',
  Spain: 'ES',
  'Czech-Republic': 'CZ',
  UK: 'UK',
  Estonia: 'EE',
  Argentina: 'AR',
  Mexico: 'MX',
  Canada: 'CA',
  NewZealand: 'NZ',
  Poland: 'PL',
  SouthAfrica: 'SA',
  Denmark: 'DK'
}

export const emailRegexp = /^(\D)+(\w)*((\.(\w)+)?)+@(\D)+(\w)*((\.(\D)+(\w)*)+)?(\.)[a-z]{2,}$/i
export const aboutText = `Every day we are faced with different financial decisions and
                  Path wants to be there for you. We hope to provide you the
                  tools you need to move forward with confidence. Whether it’s
                  financial products like personal loans, credit cards, or
                  insurance we’re here to help you take that next step with your
                  financial path!`
export const aboutHeader = `Straight forward writing that helps you simply make better
                  financial decisions.`

export const adverseUrl = 'https://adverse.lincx.la/ad'

export const upushKey = 'z8eZmsT0yKgIipt3nawUGXfRh1NxA6Qo'

export const MILLISECONDS_1_WEEK = 7 * 24 * 60 * 60 * 1000
export const MILLISECONDS_1_MONTH = 30 * 24 * 60 * 60 * 1000

export const ORIGIN_PARAM = 'lincx-origin'
export const ORIGIN_KEY = 'PM_lincx_origin'
export const EXPERIMENT_KEY = 'PM_exp'
export const NOTIFICATION_KEY = 'PM_lincx_notification'
export const STRATEGIS_END_POINT = 'https://r.strateg.is/redirect-params'
